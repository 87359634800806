@import './src/scss/variables';
@import './src/scss/game';

.game__content {
	border-radius: 50%;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(85, 185, 243);
  box-shadow: inset 20px 20px 60px rgb(72, 157, 207), inset -20px -20px 60px rgb(98, 213, 255);
  overflow: hidden;
  border: 8px solid rgb(85, 185, 243);
  max-width: 519px;
  max-height: 519px;
  height: 80vw;
  width: 80vw;

	&__row {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-top: 3px solid rgb(77, 168, 221);
	  border-bottom: 3px solid rgb(77, 168, 221);
	  width: 1000px;

	  &:last-of-type {
			border-bottom: 0;
	  }
	}
}

.circle {
  border: 2px solid rgb(85, 185, 243);
  margin: 10px 10px;
  overflow: hidden;
  cursor: pointer;
  max-width: 55px;
  max-height: 55px;
  height: 8vw;
  width: 8vw;
  border-radius: 100%;
  background: linear-gradient(145deg, rgb(106, 231, 255), rgb(64, 139, 183));
  box-shadow: 7.21px 7.21px 19px rgb(48, 105, 137), -7.21px -7.21px 19px rgb(122, 255, 255);
  transition: all 0.3s ease-in-out;

  &.in {
    background: linear-gradient(145deg, rgba(69, 150, 197, 0.49), rgba(101, 220, 255, 0.49));
    box-shadow: inset 9.91px 9.91px 15px rgb(77, 168, 221), inset -9.91px -9.91px 15px rgba(93, 202, 255, 0.49);
    pointer-events: none;
  }
}